import * as z from "zod";
import ContractTypeEnum from "@/constants/enums/ContractTypeEnum";

const ContractDetailsBaseSchema = z.object({
  name: z
    .string({
      required_error: "Name is required",
      invalid_type_error: "Name is required",
    })
    .min(1, "Name is required"),
  contractStartDate: z.date({
    required_error: "Start date is required",
    invalid_type_error: "Start date is required",
  }),
  contractType: z.string({}),
  automaticSalesApproval: z.string(),
});

const ContractEndByDateSchema = z.object({
  contractEndType: z.string(),
  contractEndDate: z.date({
    required_error: "End date is required",
    invalid_type_error: "End date is required",
  }),
});

const ContractEndByAmountSchema = z.object({
  contractEndType: z.string(),
  amount: z
    .string({
      required_error: "Amount is required",
      invalid_type_error: "Amount is required",
    })
    .min(1, "Amount is required"),
  amountUnitId: z.number({
    required_error: "Unit is required",
    invalid_type_error: "Unit is required",
  }),
});

const ContractSchema = z.discriminatedUnion("contractEndType", [
  ContractEndByDateSchema.extend({ contractEndType: z.literal(ContractTypeEnum.FIXED_EXPIRY) }),
  ContractEndByAmountSchema.extend({ contractEndType: z.literal(ContractTypeEnum.FIXED_AMOUNT) }),
]);

export const ContractDetailsSchema = ContractDetailsBaseSchema.and(ContractSchema);

export type ContractDetailsInput = z.infer<typeof ContractDetailsSchema>;
