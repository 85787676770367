import { forwardRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { postCreateCompanySpecificProduct, useGetAllCompanies } from "@/services/Company";
import { Company, CreateCompanySpecificProductDto } from "@/types";
import { ContractDetailsInput, ContractDetailsSchema } from "../validation/contract-details";
import { useMutation } from "@tanstack/react-query";
import Grid from "@/components/Grid/Grid";
import Dropdown from "@/components/Dropdown/Dropdown";
import UserContext from "@/contexts/UserContext";
import ProductDetails from "../ProductDetails";
import { CreateContractWizard } from "../helpers/helpers";

interface Props {
  onValidationSuccess: (data: any) => void;
  onChangeValues: (changed: any) => void;
  values: CreateContractWizard;
}

const ContractDetails = forwardRef<HTMLFormElement, Props>(({ onValidationSuccess, onChangeValues, values }, ref) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;

  const { data: companiesTradingPartners } = useGetAllCompanies();

  const { mutateAsync: mutateCreateProduct } = useMutation({
    mutationFn: async (data: ContractDetailsInput): Promise<number> => {
      if (!data.selectFromExisting) {
        const newProductObj: CreateCompanySpecificProductDto = {
          companyId: currentCompany.companyId,
          companySpecificProductName: data.companySpecificProductName,
          salesItemId: data.salesItemId,
          conditionId: data.conditionId,
          typeId: data.typeId,
          formatId: data.formatId,
          sizeId: data.sizeId,
          colorId: data.colorId,
          boxTypeId: data.boxTypeId,
          companySpecificProductNetwork: [currentCompany.companyId, watch("tradingPartner")],
          images: data.images,
        };

        return await postCreateCompanySpecificProduct(newProductObj).then((res) => res.data);
      }
      return 0;
    },
    onSuccess: (data, variables) => {
      onChangeValues({
        companySpecificProductId: data,
        selectFromExisting: true,
        tradingPartners: variables.tradingPartner,
      });
      onValidationSuccess(data);
    },
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ContractDetailsInput>({
    resolver: zodResolver(ContractDetailsSchema),
    defaultValues: values,
  });

  const onSubmit = async (data: ContractDetailsInput) => {
    if (!watch("selectFromExisting")) {
      await mutateCreateProduct(data);
    } else {
      onValidationSuccess(data);
      onChangeValues(data);
    }
  };

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid.Row>
          <Grid.Col span={12}>
            <Controller
              control={control}
              name="tradingPartner"
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  id="create-contract-trading-partner"
                  name="trading-partner"
                  label={t("contracts.tradingPartner")}
                  options={companiesTradingPartners ?? []}
                  getOptionLabel={(option: Company) => option.companyName}
                  getOptionValue={(option: Company) => option.id}
                  value={companiesTradingPartners?.find((company) => company.id === value)}
                  onChange={(e: Company) => {
                    onChange(e.id);
                  }}
                  errormessage={errors.tradingPartner?.message}
                />
              )}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <ProductDetails
              control={control}
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
              onChangeValues={onChangeValues}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </form>
  );
});

export default ContractDetails;
