import { TransportResponsibleType } from "@/types";
import * as z from "zod";

const transportResponsible: z.ZodType<TransportResponsibleType> = z.any();
const TransportBaseSchema = z.object({
  transportResponsible: transportResponsible,
});

export const TransportSchema = TransportBaseSchema;
export type TransportInput = z.infer<typeof TransportSchema>;

const DifferentAddress = z.object({
  address: z
    .string({
      required_error: "Address is required",
      invalid_type_error: "Address is required",
    })
    .min(1, { message: "Address is required" }),
  country: z
    .number({
      required_error: "Country is required",
      invalid_type_error: "Country is required",
    })
    .min(1, { message: "Country is required" }),
  postCode: z
    .string({
      required_error: "Postal code is required",
      invalid_type_error: "Postal code is required",
    })
    .min(1, { message: "Postal code is required" }),
  place: z
    .string({
      required_error: "Place is required",
      invalid_type_error: "Place is required",
    })
    .min(1, { message: "Place is required" }),
  addressType: z.any().optional(),
});

export const DifferentAddressSchema = DifferentAddress;
export type DifferentAddressInput = z.infer<typeof DifferentAddressSchema>;
