// import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { getSalesItemById } from "../../../../services/Orders";
import StepLabel from "../../atoms/StepLabel";
import Text from "../../atoms/Text";

import styles from "./styles.module.css";
// import { Image } from "@mantine/core";
import Stack from "../../atoms/Stack";

type SalesItem = {
  value: string;
  id: number;
};

interface Props {
  salesItem: SalesItem;
}

export default function InformationStep(props: Props) {
  const { t } = useTranslation();
  // const { salesItem } = props;
  // const [salesItemImg, setSalesItemImg] = useState('');

  // useEffect(() => {
  //   //TODO: Optimizing, could potentially only do this for screens larger than phone (this comp not visible on small screens)
  //   async function asyncGetSalesItemById() {
  //     const res = await getSalesItemById(salesItem.id);
  //     setSalesItemImg(require('../../../../assets/img' + res.data.imageUrl));
  //   }

  //   if (salesItem && salesItem.id > 0) {
  //     asyncGetSalesItemById();
  //   } else {
  //     setSalesItemImg('');
  //   }
  // }, [salesItem, salesItem.id]);

  return (
    <aside className={styles.orderInfoRoot}>
      <div className={styles.header}>
        <StepLabel label={t("common.information")} notStep={true} />
      </div>
      {/* <figure className={styles.figure}>
        <Image w='100%' src={salesItemImg} alt={salesItem.value} />
        <figcaption>
          <Text size='xs' display='title' tt='uppercase'>
            {salesItem.value}
          </Text>
        </figcaption>
      </figure> */}
      <div className={styles.text}>
        <Stack gap="16px">
          <Text size="sm" color="tertiary">
            {t("commonOrders.informationTip1")}
          </Text>
          <Text size="sm" color="tertiary">
            {t("commonOrders.informationTip2")}
          </Text>
        </Stack>
      </div>
    </aside>
  );
}
