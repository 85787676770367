import { useState, useRef, SyntheticEvent, useEffect } from "react";
import { Image } from "@mantine/core";
import SVGAsset from "../../../SVGAsset/SVGAsset";
import Text from "../../../_Reviver/atoms/Text";
import CTA from "../../../_Reviver/atoms/CTA";
import Stack from "../../../_Reviver/atoms/Stack";

import styles from "./styles.module.css";
import Group from "../../atoms/Group";

const fileUploadType = {
  SMALL: "SMALL",
} as const;

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10485760;

const convertBytesToMb = (bytes: number) => Math.round(bytes / 1024 / 1024);
export const convertNestedObjectToArray = (nestedObj: any) => Object.keys(nestedObj).map((key) => nestedObj[key]);

//TODO: det her er laget for å være temporært,
// frem til backend skrives om til formdata
export const getBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

//TODO: det her er laget for å være temporært,
// frem til backend skrives om til formdata
export const convertToStringForImages = async (files: any[]) => {
  const filesArray = convertNestedObjectToArray(files);

  const stringArray = await Promise.all(
    filesArray.map(async (file) => {
      const base64: any = await getBase64(file);
      return { image: base64.split(",")[1], fileType: "JPEG" };
    })
  );

  return stringArray;
};

export const convertToStringForDocuments = async (files: any[]) => {
  const filesArray = convertNestedObjectToArray(files);

  const stringArray = await Promise.all(
    filesArray.map(async (file) => {
      const base64: any = await getBase64(file);
      return {
        document: base64.split(",")[1],
        fileType: file.type.split("/")[1]?.toUpperCase(),
        documentTitle: file.name,
      };
    })
  );

  return stringArray;
};

interface Props {
  label: string;
  multiple?: boolean;
  updateFilesCb: (files: any[]) => void;
  maxFileSizeInBytes?: number;
  error?: string;
  files?: any[];
  componenttype?: keyof typeof fileUploadType;
}

function FileUpload({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  error,
  ...props
}: Props) {
  const fileInputField = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<any>(props.files || {});
  const [errorFileSize, setErrorFileSize] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(error);

  //returns object where key=fileName and value=fileObject
  const addNewFiles = (newFiles: any) => {
    setErrorFileSize(null);
    setErrorMessage(undefined);

    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!props.multiple) {
          return { file };
        }
        files[file.name] = file;
      } else {
        setErrorFileSize(convertBytesToMb(file.size));
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files: any[]) => {
    console.log(files);

    // const filesAsArray = convertNestedObjectToArray(files);
    // const filesAsStringArray = convertToStringForImages(files);
    updateFilesCb(files);
  };

  const uploadBtnClickHandler = () => {
    fileInputField.current?.click();
  };

  const newFileUploadHandler = (e: SyntheticEvent) => {
    const { files: newFiles } = e.target as HTMLInputElement;

    if (newFiles?.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName: string) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  useEffect(() => {
    setErrorMessage(
      error
        ? error
        : errorFileSize
          ? `Filstørrelsen er for stor (${errorFileSize} kb). Vennligst last opp en fil som er under ${convertBytesToMb(maxFileSizeInBytes)} kb`
          : ""
    );
  }, [error, errorFileSize, maxFileSizeInBytes]);

  function fileTypeSVG(fileType: string) {
    switch (fileType) {
      case "csv":
        return "excel";
      case "docx":
        return "word";
      case "pdf":
        return "pdf";
      default:
        return "file";
    }
  }

  return (
    <article className={styles.fileUploadRoot}>
      {props.componenttype === undefined && (
        <Stack align="center" gap="20">
          <section className={styles.dropZone}>
            <Stack align="center" gap="16px">
              <Text size="md" display="technical">{`Dra og slipp dine bilder av ${label} her:`}</Text>
              <SVGAsset name="drop-file" width="60px" />
              <input type="file" ref={fileInputField} onChange={newFileUploadHandler} title="" value="" {...props} />
              <Text span={true} color="secondary">
                Eller{" "}
              </Text>
              <CTA className={styles.uploadCta} id="upload-file-btn" intent="primary" onClick={uploadBtnClickHandler}>
                {props.multiple ? "Velg filer" : "Velg fil"}
              </CTA>
              <Text size="xs">{`Max file size ${convertBytesToMb(maxFileSizeInBytes)} Mb`}</Text>
              {errorMessage && (
                <Text size="sm" color="error">
                  {errorMessage}
                </Text>
              )}
            </Stack>
          </section>
        </Stack>
      )}
      {props.componenttype === fileUploadType.SMALL && (
        <>
          <Group justify="space-between">
            <input
              type="file"
              ref={fileInputField}
              onChange={newFileUploadHandler}
              title=""
              value=""
              style={{ display: "none" }}
              {...props}
            />
            <Stack>
              <Text size="sm">{`${label} (${convertBytesToMb(maxFileSizeInBytes)} Mb)`}</Text>
              <div>
                <CTA className={styles.uploadCta} id="upload-file-btn" intent="primary" onClick={uploadBtnClickHandler}>
                  {props.multiple ? "Velg filer" : "Velg fil"}
                </CTA>
              </div>
              {errorMessage && (
                <Text size="sm" color="error">
                  {errorMessage}
                </Text>
              )}
            </Stack>
          </Group>
        </>
      )}

      <section className={styles.fileList}>
        <Stack grow>
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            let isImageFile = file.type.split("/")[0] === "image";
            let fileType = fileTypeSVG(file.name.split(".")[1]);
            if (!isImageFile) {
              return (
                <li key={fileName} className={styles.filePreview}>
                  <Group justify="space-between">
                    <Group>
                      {fileType && <SVGAsset name={fileType} />}
                      <Text size="xs">{file.name}</Text>
                    </Group>
                    <Group gap={"20px"}>
                      <label className={styles.fileSize}>
                        <Text size="xs">{convertBytesToMb(file.size)} Mb</Text>
                      </label>
                      <i onClick={() => removeFile(fileName)}>
                        <SVGAsset name="trashcan" />
                      </i>
                    </Group>
                  </Group>
                </li>
              );
            }
          })}
        </Stack>
        {Object.keys(files).map((fileName, index) => {
          let file = files[fileName];
          let isImageFile = file.type.split("/")[0] === "image";
          return (
            isImageFile && (
              <li key={fileName} className={styles.imagePreview}>
                <Image className={styles.img} src={URL.createObjectURL(file)} alt={`file preview ${index}`} />
                <div>
                  <label className={styles.fileName}>
                    <Text size="xs" display="technical">
                      {file.name}
                    </Text>
                  </label>
                  <aside>
                    <label className={styles.fileSize}>
                      <Text size="xs">{convertBytesToMb(file.size)} Mb</Text>
                    </label>
                    <i className={styles.closeButton} onClick={() => removeFile(fileName)}>
                      <SVGAsset name="close-white" />
                    </i>
                  </aside>
                </div>
              </li>
            )
          );
        })}
      </section>
    </article>
  );
}

export default FileUpload;
