import { useContext, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Contract, ContractTradeType } from "@/types";
import {
  contractsUIConfig,
  getContractCardDetails,
  getContractProgressPercent,
  getContractTradeType,
} from "@/utils/Contracts";
import { deleteContract } from "@/services/Contracts";
import { useGetCreateOrderValues } from "@/services/Orders";
import { ApprovalWarningText } from "../ContractCard";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Modal, { ModalContent, ModalCTAs } from "../Modal";
import moment from "moment";
import SVGAsset from "@/components/SVGAsset/SVGAsset";
import Group from "../../atoms/Group";
import Text from "../../atoms/Text";

import styles from "./styles.module.css";
import UserContext from "@/contexts/UserContext";
import Heading from "../../atoms/Heading";
import ContractTradeTypeEnum from "@/constants/enums/ContractTradeTypeEnum";
import Progress from "../../atoms/Progress";
import Stack from "../../atoms/Stack";
import CTA from "../../atoms/CTA";
import Grid from "@/components/Grid/Grid";
import TextField from "@/components/TextField/TextField";
import RadioButtonGroup from "../../molecules/RadioButtonGroup";
import Dropdown from "@/components/Dropdown/Dropdown";
import Divider from "../../atoms/Divider";
import ContractStatusEnum from "@/constants/enums/ContractStatusEnum";

interface Props {
  backToContractsList: () => void;
  openNewOrder: () => void;
  contract: Contract;
}

export default function EditContract({ contract, backToContractsList, openNewOrder }: Props) {
  return (
    <div className={styles.editContractRoot}>
      <div className={styles.editContractInner}>
        <Group align="center">
          <SVGAsset onClick={backToContractsList} name="arrow-left" />
          <Text size="md" display="technical" tt="uppercase" strong={true}>
            Active contracts
          </Text>
        </Group>
        <EditContractHeader contract={contract} />
        <EditContractForm contract={contract} />
        <EditContractFooter contract={contract} openNewOrder={openNewOrder} backToContractList={backToContractsList} />
      </div>
    </div>
  );
}

interface EditContractFormProps {
  contract: Contract;
}

const EditContractForm: FC<EditContractFormProps> = ({ contract }) => {
  const { t } = useTranslation();
  const { data: createOrderValues } = useGetCreateOrderValues();
  const contractTypeOptions = [
    { value: "BUY", label: t("contracts.sale") },
    { value: "SELL", label: t("contracts.purchase") },
  ];
  const contractTradeTypeOptions = [
    { value: "FIXED_EXPIRY", label: t("contracts.fixedAmount") },
    { value: "FIXED_DATE", label: t("contracts.fixedDate") },
  ];

  return (
    <form className={styles.editContractForm}>
      <Grid compact={true}>
        <Grid.Row>
          <Grid.Col span={6}>
            <TextField
              id="create-contract-name"
              label={t("contracts.contractName")}
              placeholder="Minneverdig kontrakstnavn"
              disabled={true}
              value={contract.name}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextField
              id="create-contract-description"
              label={t("contracts.contractDescription")}
              placeholder="Skriv beskrivelsen"
              disabled={true}
              value={contract.description}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={3}>
            <RadioButtonGroup
              title={t("contracts.contractType")}
              name="tradeType"
              disabled={true}
              options={contractTradeTypeOptions}
              defaultValue={contractTradeTypeOptions[0].value}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <RadioButtonGroup
              name="contractType"
              title={t("contracts.contractType")}
              disabled={true}
              options={contractTypeOptions}
              defaultValue={contractTypeOptions[0].value}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <Divider visible={false} margin={false} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={6}>
            <TextField
              id="create-contract-product"
              label={t("common.product")}
              disabled={true}
              value={contract.contractProduct.companySpecificProduct.companySpecificProductName}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Dropdown
              id="create-contract-product"
              label={t("common.product")}
              isDisabled={true}
              options={createOrderValues ? createOrderValues.salesItems : []}
              value={
                createOrderValues
                  ? createOrderValues.salesItems.find(
                      (item) => item.id === contract.contractProduct.companySpecificProduct.salesItemId
                    )
                  : {}
              }
              getOptionLabel={(option) => (option.value ? t("common.plastic." + option.value) : "")}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Dropdown
              id="create-contract-condition"
              label={t("common.condition")}
              isDisabled={true}
              options={createOrderValues ? createOrderValues.conditions : []}
              value={
                createOrderValues
                  ? createOrderValues.conditions.find(
                      (condition) => condition.id === contract.contractProduct.companySpecificProduct.conditionId
                    )
                  : {}
              }
              getOptionLabel={(option) => (option.value ? t("common.conditions." + option.value) : "")}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={3}>
            <Dropdown
              id="create-contract-type"
              label={t("common.type")}
              isDisabled={true}
              options={createOrderValues ? createOrderValues.types : []}
              value={
                createOrderValues
                  ? createOrderValues.types.find(
                      (type) => type.id === contract.contractProduct.companySpecificProduct.typeId
                    )
                  : {}
              }
              getOptionLabel={(option) => (option.value ? t("common.types." + option.value) : "")}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Dropdown
              id="create-contract-size"
              label={t("common.size")}
              isDisabled={true}
              options={createOrderValues ? createOrderValues.sizes : []}
              value={
                createOrderValues
                  ? createOrderValues.sizes.find(
                      (size) => size.id === contract.contractProduct.companySpecificProduct.sizeId
                    )
                  : {}
              }
              getOptionLabel={(option) => option.value}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Dropdown
              id="create-contract-format"
              label={t("common.format")}
              isDisabled={true}
              options={createOrderValues ? createOrderValues.formats : []}
              value={
                createOrderValues
                  ? createOrderValues.formats.find(
                      (format) => format.id === contract.contractProduct.companySpecificProduct.formatId
                    )
                  : {}
              }
              getOptionLabel={(option) => (option.value ? t("common.formats." + option.value) : "")}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Dropdown
              id="create-contract-color"
              label={t("common.color")}
              isDisabled={true}
              options={createOrderValues ? createOrderValues.colors : []}
              value={
                createOrderValues
                  ? createOrderValues.colors.find(
                      (color) => color.id === contract.contractProduct.companySpecificProduct.colorId
                    )
                  : {}
              }
              getOptionLabel={(option) => (option.value ? t("common.colors." + option.value) : "")}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <Divider margin={false} />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </form>
  );
};

interface EditContractFooterProps {
  contract: Contract;
  openNewOrder: () => void;
  backToContractList: () => void;
}

const EditContractFooter: FC<EditContractFooterProps> = ({ contract, openNewOrder, backToContractList }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { mutateAsync: mutateDeleteContract } = useMutation({
    mutationFn: async (contractId: number) => {
      await deleteContract(contractId);
    },
    onSuccess: () => {
      backToContractList();
      queryClient.invalidateQueries({ queryKey: ["getContracts"] });
    },
  });

  return (
    <footer className={styles.editContractFooter}>
      <Group justify="flex-end">
        <CTA size="sm" onClick={openNewOrder} intent="affirm" tt="uppercase">
          Ny Ordre
        </CTA>
        <CTA
          size="sm"
          onClick={() => setIsDeleteDialogOpen(true)}
          intent="negate"
          disabled={contract.contractStatus !== ContractStatusEnum.PENDING_APPROVAL}
        >
          {t("contracts.deleteContract")}
        </CTA>
        <DeleteContractDialog
          isOpen={isDeleteDialogOpen}
          closeCancelConfirmation={() => setIsDeleteDialogOpen(false)}
          deleteContract={() => mutateDeleteContract(contract.id)}
        />
      </Group>
    </footer>
  );
};

interface EditContractHeaderProps {
  contract: Contract;
}

const EditContractHeader: FC<EditContractHeaderProps> = ({ contract }) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const contractTradeType = getContractTradeType(contract, currentCompany);
  const { iconName, companyIcon, partnerIcon } = contractsUIConfig(contractTradeType);
  const { sellerName, buyerName, allApproved, createdDate } = getContractCardDetails(contract);

  return (
    <header className={styles.editContractHeader}>
      <Group justify="space-between" gap="20px" align="flex-start">
        <Group className={styles.contractStatus} justify="flex-start" align="flex-start">
          <SVGAsset name={iconName} width="36" height="36" />
          <Stack grow={true}>
            <Heading order={4} styleOrder={5} strong={true}>
              {contract.name}
            </Heading>
            {!allApproved ? (
              <ProgressWithText percent={getContractProgressPercent(contract)} contractTradeType={contractTradeType} />
            ) : (
              <ApprovalWarningText />
            )}
          </Stack>
        </Group>
        <div className={styles.contractPartners}>
          <Text size="xs" className={styles.dateCreated} color="secondary">
            {createdDate && "Opprettet " + moment(createdDate).local().format("DD.MM.YYYY")}
          </Text>
          <Group gap="20px" justify="flex-end" className={`${styles.traders} ${styles[contractTradeType]}`}>
            <Text size="lg" strong={true}>
              <SVGAsset name={companyIcon} width="18" height="18" className={styles.tradeIcon} />
              {contractTradeType === ContractTradeTypeEnum.SELL ? sellerName : buyerName}
            </Text>
            <Text size="lg" strong={true}>
              <SVGAsset name={partnerIcon} width="18" height="18" className={styles.tradeIcon} />
              {contractTradeType === ContractTradeTypeEnum.SELL ? buyerName : sellerName}
            </Text>
          </Group>
        </div>
      </Group>
    </header>
  );
};

interface ProgressWithTextProps {
  contractTradeType: ContractTradeType;
  percent: number;
}

const ProgressWithText: FC<ProgressWithTextProps> = ({ contractTradeType, percent }) => (
  <Stack align="flex-end">
    <Text
      size="xs"
      color="secondary"
      style={{ position: "absolute", marginTop: "-24px" }}
    >{`${percent}% gjennomført`}</Text>
    <Progress percent={percent} contractTradeType={contractTradeType} />
  </Stack>
);

interface DeleteContractDialogProps {
  isOpen: boolean;
  closeCancelConfirmation: () => void;
  deleteContract: () => void;
}
const DeleteContractDialog: FC<DeleteContractDialogProps> = ({ isOpen, closeCancelConfirmation, deleteContract }) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen} onClose={() => closeCancelConfirmation()} headerText={t("common.confirm")}>
      <ModalContent>
        <Text>{t("contracts.deleteConfirmation")}</Text>
      </ModalContent>
      <ModalCTAs>
        <CTA id="delete-order-cancel" intent="tertiary" onClick={() => closeCancelConfirmation()}>
          {t("common.cancel")}
        </CTA>
        <CTA intent="primary" id="delete-order-confirm" onClick={() => deleteContract()}>
          {t("common.accept")}
        </CTA>
      </ModalCTAs>
    </Modal>
  );
};
