import * as z from "zod";

const PaymentBaseSchema = z.object({
  agreedPricePrKg: z
    .string({
      required_error: "Price is required",
      invalid_type_error: "Price is required",
    })
    .min(1, "Price is required"),
  currencyType: z.string({
    required_error: "Currency is required",
    invalid_type_error: "Currency is required",
  }),
  priceUnitId: z.number({
    required_error: "Unit is required",
    invalid_type_error: "Unit is required",
  }),
  invoiceDueDateId: z.object({
    id: z.number(),
    days: z.number(),
  }),
});

export const PaymentSchema = PaymentBaseSchema;
export type PaymentInput = z.infer<typeof PaymentSchema>;
