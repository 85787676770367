import * as z from "zod";
import { Image } from "@/types";

const image: z.ZodType<Image> = z.any();
export const ContractDetailsBaseSchema = z.object({
  tradingPartner: z.number({
    required_error: "Please select a trading partner",
    invalid_type_error: "Please select a trading partner",
  }),
});

const ContractDetailsNewProductSchema = z.object({
  selectFromExisting: z.boolean(),
  companySpecificProductName: z
    .string()
    .min(3, "Name must be at least 3 characters")
    .max(25, "Name must not exceed 25 characters"),
  salesItemId: z.number({
    required_error: "Please select a material",
    invalid_type_error: "Please select a material",
  }),
  conditionId: z.number({
    required_error: "Please select a condition",
    invalid_type_error: "Please select a condition",
  }),
  typeId: z.number().optional().nullable().default(null),
  formatId: z.number().optional().nullable().default(null),
  sizeId: z.number().optional().nullable().default(null),
  colorId: z.number().optional().nullable().default(null),
  boxTypeId: z.number().optional().nullable().default(null),
  companySpecificProductNetwork: z.array(z.number()).optional().nullable().default([]),
  images: z.any(),
});

const ContractDetailsExistingProductSchema = z.object({
  selectFromExisting: z.boolean(),
  companySpecificProductId: z.number(),
});

const ContractDetailsProductSchema = z.discriminatedUnion("selectFromExisting", [
  ContractDetailsExistingProductSchema.extend({ selectFromExisting: z.literal(true) }),
  ContractDetailsNewProductSchema.extend({ selectFromExisting: z.literal(false) }),
]);

export const ContractDetailsSchema = ContractDetailsBaseSchema.and(ContractDetailsProductSchema);

export type ContractDetailsInput = z.infer<typeof ContractDetailsSchema>;
