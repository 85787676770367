import moment from "moment-timezone";
import { stripTime } from "@/utils/Dates";
import ContractTypeEnum from "@/constants/enums/ContractTypeEnum";
import ContractTradeTypeEnum from "@/constants/enums/ContractTradeTypeEnum";
import TransportResponsibleEnum from "@/constants/enums/TransportResponsibleEnum";
import { ContractTradeType, ContractType, CreateAddress, CreateContract, TransportResponsibleType } from "@/types";

export interface CreateContractWizard {
  name: string;
  description: string;
  tradingPartner: number;
  contractDate: string;
  contractStartDate: string;
  contractEndDate: string | null;
  companySpecificProductId: number;
  useTransportServices: boolean;
  amount: string | null;
  agreedPricePrKg: string;
  selectedServices: number[];
  invoiceIntervalId: number;
  invoiceDueDateId: { id: number, days: string };
  transportResponsible: TransportResponsibleType;
  currencyType: number;
  comment: string | null;
  amountUnitId: number;
  priceUnitId: number;
  transportFromAddress: CreateAddress | null;
  transportToAddress: CreateAddress | null;
  attachments: any[] | null;
  automaticSalesApproval: string;

  contractEndType: ContractType,
  contractType: ContractTradeType,
  selectFromExisting: boolean,
}

export const CreateNewOrderPlaceholderObj: CreateContractWizard = {
  name: "",
  description: "",
  tradingPartner: 0,
  contractDate: stripTime(moment().toDate()),
  contractStartDate: stripTime(moment().toDate()),
  companySpecificProductId: 0,
  useTransportServices: false,
  agreedPricePrKg: "",
  selectedServices: [],
  invoiceIntervalId: 0,
  invoiceDueDateId: { id: 0, days: "" },
  transportResponsible: TransportResponsibleEnum.SELLER,
  currencyType: 0,
  priceUnitId: 0,
  contractEndDate: null,
  amount: null,
  comment: "",
  amountUnitId: 0,
  transportFromAddress: null,
  transportToAddress: null,
  attachments: null,
  contractEndType: ContractTypeEnum.FIXED_AMOUNT,
  contractType: ContractTradeTypeEnum.BUY,
  selectFromExisting: false,
  automaticSalesApproval: "NO",
}


export function ConvertFormToContract(form: CreateContractWizard, companyId: any) {
  const newContract: CreateContract = {
    name: form.name,
    description: form.description,
    contractBuyers: [],
    contractSellers: [],
    contractDate: form.contractDate,
    contractStartDate: form.contractStartDate,
    contractEndDate: form.contractEndType === ContractTypeEnum.FIXED_EXPIRY
      ? form.contractEndDate
      : null,
    companySpecificProductId: form.companySpecificProductId,
    useTransportServices: form.useTransportServices,
    amount: form.contractEndType === ContractTypeEnum.FIXED_AMOUNT && form.amount
      ? parseInt(form.amount)
      : null,
    agreedPricePrKg: parseInt(form.agreedPricePrKg),
    selectedServices: form.selectedServices,
    invoiceIntervalId: form.invoiceIntervalId,
    invoiceDueDateId: form.invoiceDueDateId.id,
    transportResponsible: form.transportResponsible,
    currencyType: form.currencyType,
    comment: form.comment,
    amountUnitId: form.amountUnitId,
    priceUnitId: form.priceUnitId,
    transportFromAddress: form.transportFromAddress,
    transportToAddress: form.transportToAddress,
    attachments: form.attachments,
    automaticSalesApproval: form.automaticSalesApproval === "YES" ? true : false,
  };

  if (form.contractType === ContractTradeTypeEnum.BUY) {
    newContract.contractSellers.push(companyId);
    newContract.contractBuyers.push(form.tradingPartner);
  } else if (form.contractType === ContractTradeTypeEnum.SELL) {
    newContract.contractBuyers.push(companyId);
    newContract.contractSellers.push(form.tradingPartner);
  }
  return newContract;
}
