import { CreateCompanySpecificProductDto } from "@/types";

export default function createCompanySpecificProductStructure() {
  return {
    companyId: 0,
    companySpecificProductName: "",
    salesItemId: 0,
    conditionId: 0,
    sizeId: null,
    colorId: null,
    formatId: null,
    typeId: null,
    boxTypeId: null,
    companySpecificProductNetwork: [],
    images: null,
    attachments: null,
  };
}
