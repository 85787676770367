import styles from "./styles.module.css";

interface Props {
  children: React.ReactNode;
}

// This is used to wrap a textfield and dropdown to make styling easier when working with react forms
export default function TextfieldDropdownWrapper({ children }: Props) {
  return <div className={`${styles.root}`}>{children}</div>;
}
