import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateOrderDropdownValue, InvoiceDueDate, PaymentSetting } from "@/types";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGetPaymentOptions } from "@/services/Settings";
import { useGetCreateOrderValues } from "@/services/Orders";
import { PaymentInput, PaymentSchema } from "../validation/payment";
import Grid from "@/components/Grid/Grid";
import Text from "@/components/_Reviver/atoms/Text";
import Divider from "@/components/_Reviver/atoms/Divider";
import RadioButtonGroup from "@/components/_Reviver/molecules/RadioButtonGroup";
import Dropdown from "@/components/Dropdown/Dropdown";
import Checkbox from "@/components/Checkbox/Checkbox";
import TextField from "@/components/TextField/TextField";
import TextfieldDropdownWrapper from "@/components/_Reviver/molecules/TextfieldDropdownWrapper/TextfieldDropdownWrapper";

interface Props {
  onValidationSuccess: (data: any) => void;
  onChangeValues: (changed: any) => void;
  values: any;
}

const Payment = forwardRef<HTMLFormElement, Props>(({ onValidationSuccess, onChangeValues, values }, ref) => {
  const { t } = useTranslation();
  const [includeFees, setIncludeFees] = useState<boolean>(false);
  const { data: createOrderValues } = useGetCreateOrderValues();
  const { data: paymentOptions } = useGetPaymentOptions();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<PaymentInput>({
    resolver: zodResolver(PaymentSchema),
    defaultValues: values,
  });

  function onSubmit(data: any) {
    onChangeValues(data);
    onValidationSuccess(data);
  }

  function calculateTotalPrice() {
    const selectedServices = values.selectedServices;
    const totalPrice = watch("agreedPricePrKg");
    const priceUnit = createOrderValues?.units.find((unit) => unit.id === watch("priceUnitId"))?.value;

    if (!totalPrice || !watch("currencyType") || !priceUnit) {
      return "0.00";
    }

    const additionalServices = paymentOptions?.settings.filter((setting) => selectedServices.includes(setting.id));
    if (!additionalServices || !includeFees) {
      return `${totalPrice} ${watch("currencyType").toUpperCase()} per ${priceUnit}`;
    }
    const total = additionalServices.reduce((acc, setting) => {
      return acc + (parseInt(totalPrice) * (setting.percentage ? setting.percentage : 0)) / 100;
    }, parseInt(totalPrice));
    return `${total.toFixed(2)} ${watch("currencyType").toUpperCase()} per ${priceUnit}`;
  }

  return (
    <>
      <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Row>
            <Grid.Col span={6}>
              <TextfieldDropdownWrapper>
                <TextField
                  id="create-order-price"
                  label={t("common.price")}
                  type="number"
                  errormessage={errors.agreedPricePrKg?.message}
                  {...register("agreedPricePrKg")}
                />
                <Controller
                  control={control}
                  name="currencyType"
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      id="create-order-currency"
                      label={t("common.currency")}
                      options={createOrderValues?.currencies ?? []}
                      getOptionLabel={(option: CreateOrderDropdownValue) => option.value.toUpperCase()}
                      getOptionValue={(option: CreateOrderDropdownValue) => option.value}
                      value={createOrderValues?.currencies.find((currency) => currency.value === value)}
                      onChange={(e: CreateOrderDropdownValue) => {
                        onChange(e.value.toUpperCase());
                      }}
                      errormessage={errors.currencyType?.message}
                    />
                  )}
                />
              </TextfieldDropdownWrapper>
            </Grid.Col>
            <Grid.Col span={2}>
              <Controller
                control={control}
                name="priceUnitId"
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    id="create-order-unit"
                    label="Per"
                    options={createOrderValues?.units ?? []}
                    getOptionLabel={(option: CreateOrderDropdownValue) => option.value}
                    getOptionValue={(option: CreateOrderDropdownValue) => option.id}
                    value={createOrderValues?.units.find((unit) => unit.id === value)}
                    onChange={(e: CreateOrderDropdownValue) => {
                      onChange(e.id);
                    }}
                    errormessage={errors.priceUnitId?.message}
                  />
                )}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={12}>
              <Text color="secondary">{t("contracts.additionalServices")}</Text>
            </Grid.Col>
            {paymentOptions
              ? paymentOptions.settings.map((setting: PaymentSetting) => (
                  <Grid.Col key={setting.id} span={4}>
                    <Checkbox
                      key={setting.id}
                      label={t("settings.additionalServices." + setting.name) + ` (${setting.percentage}%)`}
                      checked={values.selectedServices.includes(setting.id)}
                      onChange={(e: any) => {
                        const selectedServices = values.selectedServices;
                        onChangeValues({
                          selectedServices: e.target.checked
                            ? [...selectedServices, setting.id]
                            : selectedServices.filter((s: number) => s !== setting.id),
                        });
                      }}
                    />
                  </Grid.Col>
                ))
              : null}
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={12}>
              <Text color="secondary">{t("commonOrders.totalPrice")}</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <Text>{calculateTotalPrice()}</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <Checkbox
                label={t("contracts.includingFees")}
                checked={includeFees}
                onChange={(e: any) => setIncludeFees(e.target.checked)}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row className="align">
            <Grid.Col span={4}>
              <Controller
                control={control}
                name="invoiceDueDateId"
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    id="create-order-due"
                    label={t("contracts.due")}
                    options={paymentOptions ? paymentOptions.dueDates : []}
                    getOptionLabel={(option: InvoiceDueDate) => option.days}
                    getOptionValue={(option: InvoiceDueDate) => option.id}
                    value={value}
                    onChange={(e: InvoiceDueDate) => {
                      onChange(e);
                    }}
                    errormessage={errors.invoiceDueDateId?.message}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <Text size="xs" color="secondary">
                {t("contracts.dueExplanation")}
              </Text>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={12}>
              <Text color="secondary">{t("contracts.billingFrequency")}</Text>
            </Grid.Col>
            <Grid.Col span={12}>
              {paymentOptions ? (
                <RadioButtonGroup
                  name="paymentFrequency"
                  options={paymentOptions?.intervals.map((interval) => ({
                    value: interval.id.toString(),
                    label: interval.description,
                  }))}
                  defaultValue={paymentOptions.intervals
                    .find((interval: any) => interval.id == values.invoiceIntervalId)
                    ?.id.toString()}
                  onValueChange={(value: any) => {
                    onChangeValues({ invoiceIntervalId: value });
                  }}
                />
              ) : null}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </form>
    </>
  );
});

export default Payment;
