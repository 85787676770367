import { useQuery } from "@tanstack/react-query";
import { axios, APIBASE_REVIVERUSERS } from "./ApiConfig";
import { Country } from "@/types";

export const startPartialCompany = async (userId: any) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/registration/start`, {
    userId,
    partialCompany: {},
  });

export const getPartialCompany = async (userId: any) =>
  axios.get(`${APIBASE_REVIVERUSERS}api/company/registration/${userId}`);

export const updatePartialCompany = async (draft: any) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/registration/update`, draft);

export const getCountries = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/countries`);

export const useGetCountries = (options?: any) => {
  const queryOptions = {
    refetchOnWindowFocus: false,
    ...options,
  }

  return useQuery<Country[] | undefined>({
    queryKey: ['getCountries'],
    queryFn: async () => {
      const result = await getCountries();
      return result.data;
    },
    ...queryOptions,
  })
}

export const getCompanyTypes = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/types`);

export const getLanguages = async () => axios.get(`${APIBASE_REVIVERUSERS}api/language/all`);

export const getTimeZones = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/timezone/all`);

export const getAddressTypes = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/address/types`);

export const completePartialCompany = async (draft: any) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/registration/submit`, draft);

export const getMembershipTypes = async () => axios.get(`${APIBASE_REVIVERUSERS}api/company/get/membership/types`);
