import { useState, useRef, forwardRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import {
  DifferentAddressInput,
  DifferentAddressSchema,
  TransportInput,
  TransportSchema,
} from "../validation/transport";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import { useGetCountries } from "@/services/RegisterCompany";
import { Address, Country } from "@/types";
import { useGetCompanyAddresses } from "@/services/Company";

import Grid from "@/components/Grid/Grid";
import Checkbox from "@/components/Checkbox/Checkbox";
import Dropdown from "@/components/Dropdown/Dropdown";
import TextField from "@/components/TextField/TextField";
import CTA from "@/components/_Reviver/atoms/CTA";
import RadioButtonGroup from "@/components/_Reviver/molecules/RadioButtonGroup";
import Text from "@/components/_Reviver/atoms/Text";
import Divider from "@/components/_Reviver/atoms/Divider";
import Stack from "@/components/_Reviver/atoms/Stack";
import TransportResponsibleEnum from "@/constants/enums/TransportResponsibleEnum";
import UserContext from "@/contexts/UserContext";
import AddressTypeEnum from "@/constants/enums/AddressTypeEnum";
import ContractTradeTypeEnum from "@/constants/enums/ContractTradeTypeEnum";

interface Props {
  onValidationSuccess: (data: any) => void;
  onChangeValues: (changed: any) => void;
  values: any;
}

const Transport = forwardRef<HTMLFormElement, Props>(({ onValidationSuccess, onChangeValues, values }, ref) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const differentAddressNodeRef = useRef(null);
  const [differentFromIsChecked, setIsDifferentFromChecked] = useState<boolean>(false);
  const [differentToIsChecked, setIsDifferentToChecked] = useState<boolean>(false);

  const { data: countryOptions } = useGetCountries();
  const { data: companyAddresses } = useGetCompanyAddresses(userContext.currentCompany.companyId);
  const { data: companyAddressesTradingPartner } = useGetCompanyAddresses(values.tradingPartner);

  const transportResponsibleOptions = [
    { value: TransportResponsibleEnum.REVIVER, label: "Reviver" },
    { value: TransportResponsibleEnum.BUYER, label: t("commonOrders.buyer") },
    { value: TransportResponsibleEnum.SELLER, label: t("commonOrders.seller") },
  ];

  const { handleSubmit, control } = useForm<TransportInput>({
    resolver: zodResolver(TransportSchema),
    defaultValues: values,
  });

  const onSubmit = (data: any) => {
    onChangeValues(data);
    onValidationSuccess(data);
  };

  const {
    register: registerAddress,
    handleSubmit: handleSubmitAddress,
    control: controlAddress,
    getValues: getValuesAddress,
    reset: resetAddress,
    formState: { errors: errorsAddress },
  } = useForm<DifferentAddressInput>({
    resolver: zodResolver(DifferentAddressSchema),
    defaultValues: {
      address: "",
      country: 0,
      postCode: "",
      place: "",
      addressType: AddressTypeEnum.SHIPPING,
    },
  });

  const onSubmitAddress = (data: any) => {
    if (differentFromIsChecked) {
      onChangeValues({ transportFromAddress: data });
      setIsDifferentFromChecked(false);
      resetAddress();
    } else {
      onChangeValues({ transportToAddress: data });
      setIsDifferentToChecked(false);
      resetAddress();
    }
  };

  function deliverToAddress() {
    if (values.transportToAddress) {
      return `${values.transportToAddress.address}, ${values.transportToAddress.postCode} ${values.transportToAddress.place}, ${
        countryOptions?.find((country) => country.id === values.transportToAddress.country)?.name
      }`;
    }
    if (!companyAddresses || !companyAddressesTradingPartner) return;
    if (values.contractTradeType === ContractTradeTypeEnum.SELL) {
      return findAddress(companyAddresses);
    } else {
      return findAddress(companyAddressesTradingPartner);
    }
  }

  function deliverFromAddress() {
    if (values.transportFromAddress) {
      return `${values.transportFromAddress.address}, ${values.transportFromAddress.postCode} ${values.transportFromAddress.place}, ${
        countryOptions?.find((country) => country.id === values.transportFromAddress.country)?.name
      }`;
    }
    if (!companyAddresses || !companyAddressesTradingPartner) return;
    if (values.contractTradeType === ContractTradeTypeEnum.SELL) {
      return findAddress(companyAddressesTradingPartner);
    } else {
      return findAddress(companyAddresses);
    }
  }

  function findAddress(addresses: Address[]) {
    function buildAddress(address: Address) {
      return `${address.address}, ${address.postCode} ${address.place}, ${address.country.name}`;
    }
    const shippingAddress = addresses.find((address) => address.addressType === AddressTypeEnum.SHIPPING);
    if (shippingAddress) {
      return buildAddress(shippingAddress);
    }
    const visitingAddress = addresses.find((address) => address.addressType === AddressTypeEnum.VISITING);
    if (visitingAddress) {
      return buildAddress(visitingAddress);
    }
    const billingAddress = addresses.find((address) => address.addressType === AddressTypeEnum.BILLING);
    if (billingAddress) {
      return buildAddress(billingAddress);
    }
    const homeAddress = addresses.find((address) => address.addressType === AddressTypeEnum.HOME);
    if (homeAddress) {
      return buildAddress(homeAddress);
    }
  }

  return (
    <>
      <Grid>
        <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text color="secondary">{t("contracts.transportResponsible")}</Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <Controller
                control={control}
                name="transportResponsible"
                render={({ field: { onChange, value } }) => (
                  <RadioButtonGroup
                    name="transportResponsible"
                    options={transportResponsibleOptions}
                    defaultValue={value}
                    onValueChange={(value: string) => {
                      onChange(value);
                    }}
                  />
                )}
              />
            </Grid.Col>
          </Grid.Row>
        </form>

        <Grid.Row>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col span={6}>
            <Stack gap="20px">
              <Text color="secondary">Leveres fra</Text>
              <Text>{deliverFromAddress()}</Text>
              <Checkbox
                label={"Annen adresse"}
                checked={differentFromIsChecked}
                disabled={differentToIsChecked}
                onChange={() => setIsDifferentFromChecked(!differentFromIsChecked)}
              />
            </Stack>
          </Grid.Col>

          <Grid.Col span={6}>
            <Stack gap="20px">
              <Text color="secondary">Leveres til</Text>
              <Text>{deliverToAddress()}</Text>
              <Checkbox
                label={"Annen adresse"}
                checked={differentToIsChecked}
                disabled={differentFromIsChecked}
                onChange={() => setIsDifferentToChecked(!differentToIsChecked)}
              />
            </Stack>
          </Grid.Col>
        </Grid.Row>

        <CSSTransition
          in={differentFromIsChecked || differentToIsChecked}
          timeout={{
            enter: 1000,
            exit: 200,
          }}
          mountOnEnter
          unmountOnExit
          classNames="create-order-transitions"
          nodeRef={differentAddressNodeRef}
        >
          <Grid.Row>
            <Grid.Col span={12}>
              <form onSubmit={handleSubmitAddress(onSubmitAddress)}>
                <div ref={differentAddressNodeRef}>
                  <Grid.Row className="mbottom20">
                    <Grid.Col span={6}>
                      <TextField
                        id="create-order-address"
                        label={t("commonOrders.orderAddress")}
                        {...registerAddress("address")}
                        errormessage={errorsAddress?.address?.message}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Controller
                        control={controlAddress}
                        name="country"
                        render={({ field: { onChange } }) => (
                          <Dropdown
                            id="add-address-country"
                            label={t("common.country")}
                            options={countryOptions ?? []}
                            getOptionLabel={(option: Country) => option.name}
                            getOptionValue={(option: Country) => option.id}
                            onChange={(e: Country) => onChange(e.id)}
                            errormessage={errorsAddress?.country?.message}
                          />
                        )}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col span={6}>
                      <TextField
                        id="create-order-postCode"
                        label={t("commonOrders.postCode")}
                        {...registerAddress("postCode")}
                        errormessage={errorsAddress?.postCode?.message}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextField
                        id="create-order-place"
                        label={t("commonOrders.place")}
                        {...registerAddress("place")}
                        errormessage={errorsAddress?.place?.message}
                      />
                      <CTA id="checkAddress" onClick={handleSubmitAddress(onSubmitAddress)}>
                        {differentFromIsChecked ? "Lagre ny fra adresse" : "Lagre ny til adresse"}
                      </CTA>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mtop20 align"></Grid.Row>
                </div>
              </form>
            </Grid.Col>
          </Grid.Row>
        </CSSTransition>
      </Grid>
    </>
  );
});

export default Transport;
