import { useQuery } from "@tanstack/react-query";
import { axios, APIBASE_REVIVERUSERS, APIBASE_REVIVERAPPLICATION } from "./ApiConfig";
import { AllPaymentOptions, PaymentOptions } from "@/types";

export const createUser = async (firstname: any, lastname: any, email: any, role: any, companyId: any) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/user/create/advanced`, {
    firstname: firstname,
    lastname: lastname,
    email: email,
    role: role,
    companyId: companyId,
  });

export const deleteTeamMember = async (companyId: any, userId: any) =>
  axios.delete(`${APIBASE_REVIVERUSERS}api/company/${companyId}/team/delete/${userId}`);

export const postChangeRole = async (userId: any, role: any, companyId: any) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/user/role`, {
    userId: userId,
    userRoleDto: {
      roleType: role,
      companyId: companyId,
    },
  });

export const updateUser = async (
  userId: any,
  firstname: any,
  lastname: any,
  email: any,
  phone: any,
  languageId: any,
  receiveCopyOfTradingNotifications: any
) =>
  axios.put(`${APIBASE_REVIVERUSERS}api/user/update`, {
    userId: userId,
    firstname: firstname,
    lastname: lastname,
    email: email,
    phone: phone,
    languageId: languageId,
    receiveCopyOfTradingNotifications: receiveCopyOfTradingNotifications,
  });

export const updateCompany = async (company: any) => axios.post(`${APIBASE_REVIVERUSERS}api/company/update`, company);

export const postNewAddress = async (companyId: any, address: any, postCode: any, place: any, countryId: any, addressType: any) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/${companyId}/address`, {
    address,
    postCode,
    place,
    countryId,
    addressType,
  });

export const updateAddress = async (companyId: any, id: any, address: any, postCode: any, place: any, country: any, addressType: any) =>
  axios.put(`${APIBASE_REVIVERUSERS}api/company/${companyId}/address/update`, {
    id,
    address,
    postCode,
    place,
    country,
    addressType,
  });

export const deleteAddress = async (companyId: any, addressId: any) =>
  axios.delete(`${APIBASE_REVIVERUSERS}api/company/${companyId}/address/${addressId}/delete`);

export const postNewBankAccount = async (companyId: any, name: any, accountNo: any, iban: any) =>
  axios.post(`${APIBASE_REVIVERUSERS}api/company/${companyId}/account`, {
    name,
    accountNo,
    iban,
  });

export const updateBankAccount = async (companyId: any, id: any, name: any, accountNo: any, iban: any) =>
  axios.put(`${APIBASE_REVIVERUSERS}api/company/${companyId}/account/update`, {
    id,
    name,
    accountNo,
    iban,
  });

export const deleteBankAccount = async (companyId: any, accountId: any) =>
  axios.delete(`${APIBASE_REVIVERUSERS}api/company/${companyId}/account/${accountId}/delete`);

const getPaymentOptions = async () =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/payments/options`);

export const useGetPaymentOptions = (options?: any) => {
  const queryOptions = {
    refetchOnWindowFocus: false,
    ...options,
  };

  return useQuery<AllPaymentOptions | undefined>({
    queryKey: ['getPaymentOptions'],
    queryFn: async () => {
      const result = await getPaymentOptions();
      return result.data;
    },
    ...queryOptions,
  });
}

const getPaymentOptionsByCompanyId = async (companyId: any) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/payments/options/${companyId}`);

export const useGetPaymentOptionsByCompanyId = (companyId: number, options?: any) => {
  const queryOptions = {
    refetchOnWindowFocus: false,
    ...options,
  };

  return useQuery<PaymentOptions | undefined>({
    queryKey: ['getPaymentOptionsByCompanyId', companyId],
    queryFn: async () => {
      const result = await getPaymentOptionsByCompanyId(companyId);
      return result.data;
    },
    ...queryOptions,
  });
}