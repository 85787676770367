import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import FileUpload, { convertToStringForDocuments, convertToStringForImages } from "../../FileUpload";
import Grid from "@/components/Grid/Grid";
import TextField from "@/components/TextField/TextField";
import { getUserContextIds } from "@/hooks/useUserContext";

interface Props {
  onValidationSuccess: (data: any) => void;
  onChangeValues: (changed: any) => void;
  values: any;
}

const Attachments = forwardRef<HTMLFormElement, Props>(({ onValidationSuccess, onChangeValues, values }, ref) => {
  const { t } = useTranslation();
  const userIds = getUserContextIds();

  const onChangeFiles = async (files: any[]) => {
    const convertedFiles = await convertToStringForDocuments(files);

    return onChangeValues({
      attachments: convertedFiles.map((file) => ({
        ...file,
        languageId: 1,
        companyIds: [values.tradingPartner, userIds.companyId],
        documentType: "EXPORT_DOCUMENT",
      })),
    });
  };

  return (
    <>
      <form ref={ref} onSubmit={() => onValidationSuccess(values)}>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <FileUpload
                label="dokumenter, regneark, bilder, PDF osv."
                updateFilesCb={onChangeFiles}
                error=""
                files={values?.attachments}
                multiple={true}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={10}>
              <TextField
                id="create-contract-comments"
                label={t("contracts.moreComments")}
                value={values.comment}
                onChange={(e: any) => onChangeValues({ comment: e.target.value })}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </form>
    </>
  );
});

export default Attachments;
