import { useState, useEffect, useContext, ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import {
  getValidConditions,
  getBoxtypeValues,
  getValidTypes,
  useGetCreateOrderValues,
} from "../../../../services/Orders";
import {
  postCreateCompanySpecificProduct,
  deleteCompanySpecificProduct,
  getCompanyNetworkFull,
  updateCompanySpecificProduct,
  useGetCompanySpecificProducts,
} from "../../../../services/Company";
import { companySpecificProductSchema } from "./validation/SettingsValidationSchemas";
import { validateFieldsHelper } from "../../../../utils/ValidationHelper";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Modal, { ModalContent, ModalCTAs } from "../../organisms/Modal";

import createCompanySpecificProductStructure from "../../../../structures/CreateCompanySpecificProduct";
import UserContext from "../../../../contexts/UserContext";
import Grid from "../../../Grid/Grid";
import Dropdown from "../../../Dropdown/Dropdown";
import Textfield from "../../../TextField/TextField";
import DropdownMulti from "../../../DropdownMulti/DropdownMulti";

import { SettingsFormContent, SettingsFormCTAs } from ".";
import Heading from "../../atoms/Heading";
import Text from "../../atoms/Text";
import CTA from "../../atoms/CTA";
import Space from "../../atoms/Space";
import {
  BoxType,
  Company,
  Condition,
  DropdownOption,
  MessageBarOptions,
  CompanySpecificProduct,
  CompanySpecificProductOption,
  ValidationErrors,
  Type,
  CreateOrderDropdownValue,
  CreateCompanySpecificProductDto,
} from "types";
import MessageBar from "@/components/MessageBar/MessageBar";
import { isViewer } from "@/utils/User";

export default function CompanySpecificProductSettings() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany, user } = userContext;
  const queryClient = useQueryClient();

  const [companySpecificProduct, setCompanySpecificProduct] = useState(createCompanySpecificProductStructure());
  //dropdowns
  const [companySpecificProductOptions, setCompanySpecificProductOptions] = useState<CompanySpecificProductOption[]>(
    []
  );
  const [selectedCompanySpecificProduct, setSelectedCompanySpecificProduct] =
    useState<CompanySpecificProductOption | null>(null);
  const [salesItemsOptions, setSalesItemsOptions] = useState<CreateOrderDropdownValue[]>([]);
  const [selectedSalesItem, setSelectedSalesItem] = useState<CreateOrderDropdownValue | null>(null);
  const [typeOptions, setTypeOptions] = useState<DropdownOption[]>([]);
  const [selectedType, setSelectedType] = useState<DropdownOption | null>(null);
  const [conditionOptions, setConditionsOptions] = useState<DropdownOption[]>([]);
  const [selectedCondition, setSelectedCondition] = useState<DropdownOption | null>(null);
  const [selectedSize, setSelectedSize] = useState<CreateOrderDropdownValue | null>(null);
  const [formatOptions, setFormatOptions] = useState<CreateOrderDropdownValue[]>([]);
  const [selectedFormat, setSelectedFormat] = useState<CreateOrderDropdownValue | null>(null);
  const [colorOptions, setColorOptions] = useState<CreateOrderDropdownValue[]>([]);
  const [selectedColor, setSelectedColor] = useState<CreateOrderDropdownValue | null>(null);
  const [boxTypeOptions, setBoxTypeOptions] = useState<DropdownOption[]>([]);
  const [selectedBoxType, setSelectedBoxType] = useState<DropdownOption | null>(null);
  const [companiesOptions, setCompaniesOptions] = useState<DropdownOption[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<DropdownOption[]>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  //validation
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [messageBarOptions, setMessageBarOptions] = useState<MessageBarOptions | null>(null);

  const { data: dropdownValues } = useGetCreateOrderValues();
  const { data: companySpecificProducts } = useGetCompanySpecificProducts(currentCompany.companyId);
  const { mutateAsync: mutateUpdateCompanySpecificProduct } = useMutation({
    mutationFn: async (data: CompanySpecificProduct) => {
      await updateCompanySpecificProduct(data);
    },
    onSuccess: () => {
      setMessageBarOptions({ message: t("settings.updateCompanySpecificProductSuccess"), type: "Positive" });
    },
  });
  const { mutateAsync: mutateCreateCompanySpecificProduct } = useMutation({
    mutationFn: async (data: CreateCompanySpecificProductDto) => {
      await postCreateCompanySpecificProduct(data);
    },
    onSuccess: () => {
      setMessageBarOptions({ message: t("settings.addCompanySpecificProductSuccess"), type: "Positive" });
    },
  });
  const { mutateAsync: mutateDeleteCompanySpecificProduct } = useMutation({
    mutationFn: async (companySpecificProductId: number) => {
      await deleteCompanySpecificProduct(companySpecificProductId);
    },
    onSuccess: () => {
      setCompanySpecificProduct(createCompanySpecificProductStructure());
      resetDropdowns();
      setMessageBarOptions({ message: t("settings.deleteCompanySpecificProduct"), type: "Warning" });
      setIsDeleteDialogOpen(false);
      queryClient.invalidateQueries({ queryKey: ["getCompanySpecificProduct"] });
    },
  });

  useEffect(() => {
    if (dropdownValues) {
      const _salesItemsOptions = dropdownValues.salesItems.map((salesItem) => ({
        ...salesItem,
        value: t("common.plastic." + salesItem.value),
      }));
      setSalesItemsOptions(_salesItemsOptions);

      const _formatOptions = dropdownValues.formats.map((format) => ({
        ...format,
        value: t("common.formats." + format.value),
      }));
      setFormatOptions(_formatOptions);

      const _colorOptions = dropdownValues.colors.map((color) => ({
        ...color,
        value: t("common.colors." + color.value),
      }));
      setColorOptions(_colorOptions);
    }
  }, [dropdownValues, t]);

  useEffect(() => {
    async function loadOptions() {
      async function asyncGetBoxTypeValues() {
        const values = await getBoxtypeValues();
        return values.data;
      }
      const boxTypeValues = await asyncGetBoxTypeValues();

      //  BoxType options
      const _boxTypeOptions = boxTypeValues.boxtypes.map((boxType: BoxType) => ({
        value: boxType.id,
        label: t("common.boxtypes." + boxType.name),
      }));
      setBoxTypeOptions(_boxTypeOptions);
    }

    // Companies
    async function asyncGetAllCompanies() {
      const availableCompanies = await getCompanyNetworkFull(currentCompany.companyId);

      const _availableCompanies = availableCompanies.data.companies.map((company: Company) => ({
        value: company.id,
        label: company.companyName,
      }));
      setCompaniesOptions(_availableCompanies);
    }

    loadOptions();
    asyncGetAllCompanies();
  }, [t, currentCompany.companyId]);

  async function asyncLoadValidConditions(salesItemId: number) {
    async function asyncGetValidConditions() {
      const conditions = await getValidConditions(salesItemId);
      return conditions.data;
    }

    const conditions = await asyncGetValidConditions();

    const _conditionOptions = conditions.map((condition: Condition) => ({
      value: condition.id,
      label: t("common.conditions." + condition.condition),
    }));

    setConditionsOptions(_conditionOptions);

    return _conditionOptions;
  }

  async function asyncLoadValidTypes(salesItemId: number, conditionId: number) {
    async function asyncGetValidTypes() {
      const types = await getValidTypes(salesItemId, conditionId);
      return types.data;
    }

    const types = await asyncGetValidTypes();

    const _typeOptions = types.map((type: Type) => ({
      value: type.id,
      label: t("common.types." + type.type),
    }));

    setTypeOptions(_typeOptions);

    return _typeOptions;
  }

  function resetDropdowns() {
    setSelectedCompanySpecificProduct(null);
    setSelectedSalesItem(null);
    setSelectedCondition(null);
    setSelectedSize(null);
    setSelectedFormat(null);
    setSelectedColor(null);
    setSelectedType(null);
    setSelectedBoxType(null);
    setSelectedCompanies([]);
  }

  async function onChangeSelectedCompanySpecificProduct(e: any) {
    setSelectedCompanySpecificProduct(e);
    setCompanySpecificProduct(e.product);

    async function setSalesItemConditionType(selectedCompanySpecificProduct: CompanySpecificProduct) {
      const _selectedSalesItem = salesItemsOptions.find(
        (salesItem) => salesItem.id === selectedCompanySpecificProduct.salesItemId
      );

      setSelectedSalesItem(_selectedSalesItem || null);

      const _conditionOptions = _selectedSalesItem && (await asyncLoadValidConditions(_selectedSalesItem.id));
      const _selectedCondition = _conditionOptions.find(
        (condition: DropdownOption) => condition.value === selectedCompanySpecificProduct.conditionId
      );
      setSelectedCondition(_selectedCondition);

      const _typeOptions =
        _selectedSalesItem && (await asyncLoadValidTypes(_selectedSalesItem.id, _selectedCondition.value));
      const _selectedType = _typeOptions.find(
        (type: DropdownOption) => type.value === selectedCompanySpecificProduct.typeId
      );
      setSelectedType(_selectedType);
    }

    await setSalesItemConditionType(e.product);
    const _selectedSize = dropdownValues?.sizes.find((size) => size.id === e.product.sizeId);
    setSelectedSize(_selectedSize || null);
    const _selectedColor = colorOptions.find((color) => color.id === e.product.colorId);
    setSelectedColor(_selectedColor || null);
    const _selectedFormat = formatOptions.find((format) => format.id === e.product.formatId);
    setSelectedFormat(_selectedFormat || null);
    const _selectedBoxType = boxTypeOptions.find((boxtype) => boxtype.value === e.product.boxTypeId);
    setSelectedBoxType(_selectedBoxType || null);
    const _selectedCompanies = companiesOptions.filter((company) =>
      e.product.companySpecificProductNetwork?.some(
        (product: CompanySpecificProduct) => product.companyId === company.value
      )
    );
    setSelectedCompanies(_selectedCompanies);
  }

  function newCompanySpecificProductHandler() {
    setCompanySpecificProduct(createCompanySpecificProductStructure());
    resetDropdowns();
  }

  async function saveHandler() {
    const validationResult = validateFieldsHelper(companySpecificProductSchema(t), companySpecificProduct);
    setValidationErrors(validationResult as any);

    if (Object.keys(validationResult || {}).length) return;
    companySpecificProduct.companyId = currentCompany.companyId;

    if (selectedCompanySpecificProduct) {
      const productForUpdate = { ...companySpecificProduct, id: selectedCompanySpecificProduct.value };
      await mutateUpdateCompanySpecificProduct(productForUpdate);
      return;
    } else {
      await mutateCreateCompanySpecificProduct(companySpecificProduct);
      return;
    }
  }

  async function deleteHandler() {
    await mutateDeleteCompanySpecificProduct(selectedCompanySpecificProduct!.value);
  }

  function onChangeCompanySpecificProduct(change: any) {
    setCompanySpecificProduct({
      ...companySpecificProduct,
      ...change,
    });
  }

  async function onChangeSalesItem(salesItem: CreateOrderDropdownValue) {
    setSelectedSalesItem(salesItem);
    await asyncLoadValidConditions(salesItem.id);
    onChangeCompanySpecificProduct({ salesItemId: salesItem.id, conditionId: 0, typeId: 0 });
    setSelectedCondition(null);
    setSelectedType(null);
  }
  async function onChangeCondition(e: any) {
    setSelectedCondition(e);
    setSelectedType(null);
    selectedSalesItem && (await asyncLoadValidTypes(selectedSalesItem.id, e.value));
    onChangeCompanySpecificProduct({ conditionId: e.value, typeId: 0 });
  }
  function onChangeSize(size: CreateOrderDropdownValue) {
    setSelectedSize(size);
    onChangeCompanySpecificProduct({ sizeId: size.id });
  }
  function onChangeColor(color: CreateOrderDropdownValue) {
    setSelectedColor(color);
    onChangeCompanySpecificProduct({ colorId: color.id });
  }
  function onChangeformat(format: CreateOrderDropdownValue) {
    setSelectedFormat(format);
    onChangeCompanySpecificProduct({ formatId: format.id });
  }
  function onChangeType(e: any) {
    setSelectedType(e);
    onChangeCompanySpecificProduct({ typeId: e.value });
  }
  function onChangeBoxType(e: any) {
    setSelectedBoxType(e);
    onChangeCompanySpecificProduct({ boxTypeId: e.value });
  }
  function onChangeTargetCompanies(e: any) {
    setSelectedCompanies(e);
    const companies = e.map((company: DropdownOption) => {
      return company.value;
    });
    onChangeCompanySpecificProduct({ companySpecificProductNetwork: companies });
  }

  return (
    <>
      <Heading order={2} styleOrder={4} tt="uppercase">
        {t("settings.companySpecificProduct")}
      </Heading>
      <SettingsFormContent>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="md" strong={true} display="technical" tt="uppercase">
                {t("settings.chooseExistingCompanySpecificProduct")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={10}>
              <Dropdown
                id="product"
                label={t("settings.existingCompanySpecificProduct")}
                options={
                  companySpecificProducts
                    ? companySpecificProducts.map((product) => {
                        return { value: product.id, label: product.companySpecificProductName, product: product };
                      })
                    : []
                }
                value={selectedCompanySpecificProduct}
                onChange={(e) => onChangeSelectedCompanySpecificProduct(e)}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Space height="24px" />
              <CTA id="new-product" intent="secondary" onClick={() => newCompanySpecificProductHandler()}>
                {t("settings.newCompanySpecificProduct")}
              </CTA>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </SettingsFormContent>
      <SettingsFormContent>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="md" strong={true} display="technical" tt="uppercase">
                {t("settings.newCompanySpecificProduct")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={10}>
              <Textfield
                id="product-name"
                placeholder={t("settings.companySpecificProductName")}
                label={t("settings.companySpecificProductName")}
                value={companySpecificProduct.companySpecificProductName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onChangeCompanySpecificProduct({ companySpecificProductName: e.target.value })
                }
                errormessage={validationErrors.companySpecificProductName}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Dropdown
                id="sales-item"
                label={t("common.material")}
                options={salesItemsOptions}
                getOptionLabel={(option) => option.value}
                value={selectedSalesItem}
                onChange={(e: CreateOrderDropdownValue) => onChangeSalesItem(e)}
                errormessage={validationErrors.salesItemId}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Dropdown
                id="condition"
                label={t("commonOrders.conditions")}
                options={conditionOptions}
                value={selectedCondition}
                onChange={(e: ChangeEvent) => onChangeCondition(e)}
                errormessage={validationErrors.conditionId}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Dropdown
                id="type"
                label={t("common.type")}
                options={typeOptions}
                value={selectedType}
                onChange={(e: ChangeEvent) => onChangeType(e)}
                errormessage={validationErrors.typeId}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Dropdown
                id="format"
                label={t("common.format")}
                options={formatOptions}
                getOptionLabel={(option) => option.value}
                value={selectedFormat}
                onChange={(e: CreateOrderDropdownValue) => onChangeformat(e)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Dropdown
                id="size"
                label={t("commonOrders.sizes")}
                options={dropdownValues ? dropdownValues.sizes : []}
                getOptionLabel={(option) => option.value}
                value={selectedSize}
                onChange={(e: CreateOrderDropdownValue) => onChangeSize(e)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Dropdown
                id="color"
                label={t("common.color")}
                options={colorOptions}
                getOptionLabel={(option) => option.value}
                value={selectedColor}
                onChange={(e: CreateOrderDropdownValue) => onChangeColor(e)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Dropdown
                id="box-type"
                label={t("common.boxtype")}
                options={boxTypeOptions}
                value={selectedBoxType}
                onChange={(e) => onChangeBoxType(e)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <DropdownMulti
                id="network"
                label={t("common.companyToTradeWith")}
                closeMenuOnSelect={false}
                menuPlacement="top"
                options={companiesOptions}
                value={selectedCompanies}
                onChange={(e) => onChangeTargetCompanies(e)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              {messageBarOptions && <MessageBar type={messageBarOptions.type} message={messageBarOptions.message} />}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </SettingsFormContent>
      {!isViewer(user, currentCompany) && (
        <SettingsFormCTAs>
          <CTA
            size="lg"
            id="delete"
            intent="secondary"
            disabled={!selectedCompanySpecificProduct}
            onClick={() => setIsDeleteDialogOpen(true)}
          >
            {t("settings.delete")}
          </CTA>
          <CTA size="lg" id="save" intent="primary" onClick={() => saveHandler()}>
            {t("common.save")}
          </CTA>
        </SettingsFormCTAs>
      )}
      <DeleteContractDialog
        isOpen={isDeleteDialogOpen}
        closeCancelConfirmation={() => setIsDeleteDialogOpen(false)}
        deleteContract={() => deleteHandler()}
      />
    </>
  );
}

interface DeleteProductDialogProps {
  isOpen: boolean;
  closeCancelConfirmation: () => void;
  deleteContract: () => void;
}
const DeleteContractDialog: FC<DeleteProductDialogProps> = ({ isOpen, closeCancelConfirmation, deleteContract }) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen} onClose={() => closeCancelConfirmation()} headerText={t("common.confirm")}>
      <ModalContent>
        <Text>{t("settings.deleteProductConfirmation")}</Text>
      </ModalContent>
      <ModalCTAs>
        <CTA id="delete-order-cancel" intent="tertiary" onClick={() => closeCancelConfirmation()}>
          {t("common.cancel")}
        </CTA>
        <CTA intent="primary" id="delete-order-confirm" onClick={() => deleteContract()}>
          {t("common.accept")}
        </CTA>
      </ModalCTAs>
    </Modal>
  );
};
