import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ContractDetailsInput, ContractDetailsSchema } from "../validation/contract-settings";
import { CreateOrderDropdownValue } from "@/types";
import { useGetCreateOrderValues } from "@/services/Orders";

import Grid from "@/components/Grid/Grid";
import TextfieldDropdownWrapper from "@/components/_Reviver/molecules/TextfieldDropdownWrapper/TextfieldDropdownWrapper";
import TextField from "@/components/TextField/TextField";
import DatePicker from "@/components/DatePicker/DatePicker";
import ContractTypeEnum from "@/constants/enums/ContractTypeEnum";
import Divider from "@/components/_Reviver/atoms/Divider";
import RadioButtonGroup from "@/components/_Reviver/molecules/RadioButtonGroup";
import ContractTradeTypeEnum from "@/constants/enums/ContractTradeTypeEnum";
import Dropdown from "@/components/Dropdown/Dropdown";

interface Props {
  onValidationSuccess: (data: any) => void;
  onChangeValues: (changed: any) => void;
  values: any;
}

const ContractSettings = forwardRef<HTMLFormElement, Props>(({ onValidationSuccess, onChangeValues, values }, ref) => {
  const { t } = useTranslation();
  const { data: createOrderValues } = useGetCreateOrderValues();

  const contractTradeTypeOptions = [
    { value: ContractTradeTypeEnum.SELL, label: t("contracts.sale") },
    { value: ContractTradeTypeEnum.BUY, label: t("contracts.purchase") },
  ];
  const contractTradeByOptions = [
    { value: ContractTypeEnum.FIXED_AMOUNT, label: t("common.amount") },
    { value: ContractTypeEnum.FIXED_EXPIRY, label: t("common.date") },
  ];
  const automaticMatchOptions = [
    { value: "YES", label: t("common.yes") },
    { value: "NO", label: t("common.no") },
  ];

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<ContractDetailsInput>({
    resolver: zodResolver(ContractDetailsSchema),
    defaultValues: values,
  });

  const onSubmit = async (data: ContractDetailsInput) => {
    onChangeValues(data);
    onValidationSuccess(data);
  };

  return (
    <>
      <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
        <Grid compact={true}>
          <Grid.Row>
            <Grid.Col span={12}>
              <TextField
                id="create-contract-contract-name"
                label={t("contracts.contractName")}
                placeholder={t("contracts.contractName")}
                errormessage={errors.name?.message}
                {...register("name")}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Controller
                control={control}
                name="contractStartDate"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    id="create-order-startDate"
                    label={t("contracts.startDate")}
                    value={value}
                    onChange={onChange}
                    //@ts-ignore - TODO: fix this, need a typeguard or something. It's guarenteed that contractStartDate is present with the === FIXED_EXPIRY check above.
                    errormessage={errors.contractStartDate?.message}
                  />
                )}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Controller
                control={control}
                name="contractType"
                render={({ field: { onChange, value } }) => (
                  <RadioButtonGroup
                    title={t("contracts.contractType")}
                    name={"contractType"}
                    options={contractTradeTypeOptions}
                    defaultValue={value}
                    onValueChange={(value: string) => {
                      onChange(value);
                    }}
                    inlineTitle={true}
                  />
                )}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Controller
                control={control}
                name="contractEndType"
                render={({ field: { onChange, value } }) => (
                  <RadioButtonGroup
                    title={t("contracts.tradeBy")}
                    name={"contractEndType"}
                    options={contractTradeByOptions}
                    defaultValue={value}
                    onValueChange={(value: string) => {
                      onChange(value);
                    }}
                    inlineTitle={true}
                  />
                )}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>
          {watch("contractEndType") === ContractTypeEnum.FIXED_AMOUNT && (
            <Grid.Row>
              <Grid.Col span={6}>
                {/* <Group gap={0} align="start"> */}
                <TextfieldDropdownWrapper>
                  <TextField
                    id="create-contract-amount"
                    label={t("common.amount")}
                    type="number"
                    placeholder={t("common.amount")}
                    //@ts-ignore - TODO: fix this, need a typeguard or something. It's guarenteed that amount is present with the === FIXED_AMOUNT check above.
                    errormessage={errors.amount?.message}
                    {...register("amount")}
                  />
                  <Controller
                    control={control}
                    name="amountUnitId"
                    render={({ field: { onChange, value } }) => (
                      <Dropdown
                        id="create-contract-unit"
                        label={"Unit"}
                        options={createOrderValues?.units ?? []}
                        getOptionLabel={(option: CreateOrderDropdownValue) => option.value}
                        getOptionValue={(option: CreateOrderDropdownValue) => option.id}
                        value={createOrderValues?.units?.find(
                          (option: CreateOrderDropdownValue) => option.id === value
                        )}
                        onChange={(e: CreateOrderDropdownValue) => {
                          onChange(e.id);
                        }}
                        //@ts-ignore - TODO: fix this, need a typeguard or something. It's guarenteed that unitId is present with the === FIXED_AMOUNT check above.
                        errormessage={errors.amountUnitId?.message}
                      />
                    )}
                  />
                </TextfieldDropdownWrapper>
                {/* </Group> */}
              </Grid.Col>
            </Grid.Row>
          )}
          {watch("contractEndType") === ContractTypeEnum.FIXED_EXPIRY && (
            <Grid.Row>
              <Grid.Col span={6}>
                <Controller
                  control={control}
                  name="contractEndDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      id="create-order-endDate"
                      label={t("contracts.endDate")}
                      value={value}
                      onChange={onChange}
                      //@ts-ignore - TODO: fix this, need a typeguard or something. It's guarenteed that contractEndDate is present with the === FIXED_EXPIRY check above.
                      errormessage={errors.contractEndDate?.message}
                    />
                  )}
                />
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Controller
                control={control}
                name="automaticSalesApproval"
                render={({ field: { onChange, value } }) => (
                  <RadioButtonGroup
                    title={t("contracts.automaticSalesMatch")}
                    name={"automaticSalesApproval"}
                    options={automaticMatchOptions}
                    defaultValue={value}
                    onValueChange={(value: string) => {
                      onChange(value);
                    }}
                    inlineTitle={true}
                  />
                )}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </form>
    </>
  );
});

export default ContractSettings;
