import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetValidConditions, useGetValidTypes, useGetCreateOrderValues } from "@/services/Orders";
import { Controller, UseFormRegister, UseFormWatch, UseFormSetValue } from "react-hook-form";
import { CompanySpecificProduct, Condition, CreateOrderDropdownValue, Type } from "@/types";
import { ContractDetailsInput } from "../validation/contract-details";
import { useGetCompanySpecificProducts } from "@/services/Company";

import Grid from "@/components/Grid/Grid";
import Dropdown from "@/components/Dropdown/Dropdown";
import UserContext from "@/contexts/UserContext";
import RadioButtonGroup from "@/components/_Reviver/molecules/RadioButtonGroup";
import TextField from "@/components/TextField/TextField";
import Divider from "@/components/_Reviver/atoms/Divider";
import FileUpload, { convertToStringForImages } from "../../FileUpload";
import Group from "@/components/_Reviver/atoms/Group";
import ImageViewer from "../../ImageViewer/ImageViewer";

interface Props {
  control: any;
  register: UseFormRegister<ContractDetailsInput>;
  errors: any;
  watch: UseFormWatch<ContractDetailsInput>;
  setValue: UseFormSetValue<ContractDetailsInput>;
  onChangeValues: (changed: any) => void;
}

export default function ProductDetails({ control, register, errors, watch, setValue }: Props) {
  const { t } = useTranslation();
  const [selectedProduct, setSelectedProduct] = useState<CompanySpecificProduct | null>(null);
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const selectedMaterial = watch("salesItemId", 0);
  const selectedCondition = watch("conditionId", 0);

  const productTypeOptions = [
    { value: "new", label: t("contracts.newProduct") },
    { value: "saved", label: t("contracts.existingProduct") },
  ];

  const { data: companySpecificProducts } = useGetCompanySpecificProducts(currentCompany.companyId);
  const { data: dropdownValues } = useGetCreateOrderValues();
  const { data: validConditions } = useGetValidConditions(selectedMaterial, {
    enabled: selectedMaterial > 0,
  });
  const { data: validTypes } = useGetValidTypes(selectedMaterial, selectedCondition, {
    enabled: selectedMaterial > 0 && selectedCondition > 0,
  });

  function resetConditionAndType() {
    setValue("conditionId", 0);
    resetType();
  }
  function resetType() {
    setValue("typeId", 0);
  }

  const onChangeFiles = async (files: any[]) => {
    const convertedFiles = await convertToStringForImages(files);
    return setValue("images", convertedFiles);
  };

  return (
    <>
      <Grid.Row>
        <Grid.Col span={12}>
          <Controller
            control={control}
            name="selectFromExisting"
            render={({ field: { onChange, value } }) => (
              <RadioButtonGroup
                id="create-contract-select-product-type"
                title="Handelsvare"
                name={"selectFromExisting"}
                options={productTypeOptions}
                defaultValue={value ? "saved" : "new"}
                onValueChange={(val) => {
                  onChange(val === "saved" ? true : false);
                }}
                inlineTitle={true}
              />
            )}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={12}>
          <Divider />
        </Grid.Col>
      </Grid.Row>
      {watch("selectFromExisting") ? (
        <Grid.Row>
          <Grid.Col span={6}>
            <Controller
              control={control}
              name="companySpecificProductId"
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  id="create-contract-existing-product"
                  name="saved-products"
                  label={t("contracts.existingProduct")}
                  options={companySpecificProducts ?? []}
                  getOptionLabel={(option: CompanySpecificProduct) => option.companySpecificProductName}
                  getOptionValue={(option: CompanySpecificProduct) => option.id}
                  value={companySpecificProducts?.find((product) => product.id === value)}
                  onChange={(e) => {
                    onChange(e.id);
                    setSelectedProduct(e);
                  }}
                  errormessage={errors.companySpecificProductId?.message}
                />
              )}
            />
          </Grid.Col>
          {/* TODO: dette må inn når ImageViewer er ferdig og backend på plass */}
          {/* <Grid.Col span={6}>
            <ImageViewer images={selectedProduct ? selectedProduct.images : null} />
          </Grid.Col> */}
        </Grid.Row>
      ) : (
        <Grid.Row>
          <Grid.Col span={6}>
            <Group>
              <TextField
                id="create-contract-new-product-name"
                label={t("contracts.newProduct")}
                placeholder="Product name"
                errormessage={errors.companySpecificProductName?.message}
                {...register("companySpecificProductName")}
              />
            </Group>
          </Grid.Col>
          <Grid.Col span={6}>
            <FileUpload label={t("common.images")} componenttype="SMALL" multiple updateFilesCb={onChangeFiles} />
          </Grid.Col>
        </Grid.Row>
      )}
      {!watch("selectFromExisting") && (
        <>
          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Controller
                control={control}
                name="salesItemId"
                render={({ field: { onChange } }) => (
                  <Dropdown
                    id="create-contract-product-material"
                    label={t("common.material")}
                    options={dropdownValues?.salesItems ?? []}
                    getOptionLabel={(option: CreateOrderDropdownValue) => t("common.plastic." + option.value)}
                    getOptionValue={(option: CreateOrderDropdownValue) => option.id}
                    errormessage={errors.salesItemId?.message}
                    onChange={(e: CreateOrderDropdownValue) => {
                      onChange(e.id);
                      resetConditionAndType();
                    }}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                control={control}
                name="conditionId"
                render={({ field: { onChange } }) => (
                  <Dropdown
                    id="create-contract-product-condition"
                    name="condition"
                    label={t("common.condition")}
                    options={validConditions ?? []}
                    getOptionLabel={(option: Condition) => t("common.conditions." + option.condition)}
                    getOptionValue={(option: Condition) => option.id}
                    errormessage={errors.conditionId?.message}
                    onChange={(e: Condition) => {
                      onChange(e.id);
                      resetType();
                    }}
                  />
                )}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Controller
                control={control}
                name="typeId"
                render={({ field: { onChange } }) => (
                  <Dropdown
                    id="create-contract-product-type"
                    label={t("common.type")}
                    options={validTypes ?? []}
                    getOptionLabel={(option: Type) => t("common.types." + option.type)}
                    getOptionValue={(option: Type) => option.id}
                    onChange={(e: Type) => onChange(e.id)}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                control={control}
                name="formatId"
                render={({ field: { onChange } }) => (
                  <Dropdown
                    id="create-contract-product-format"
                    label={t("common.format")}
                    options={dropdownValues?.formats ?? []}
                    getOptionLabel={(option: CreateOrderDropdownValue) => t("common.formats." + option.value)}
                    getOptionValue={(option: CreateOrderDropdownValue) => option.id}
                    onChange={(e: CreateOrderDropdownValue) => onChange(e.id)}
                  />
                )}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Controller
                control={control}
                name="sizeId"
                render={({ field: { onChange } }) => (
                  <Dropdown
                    id="create-contract-product-size"
                    label={t("common.size")}
                    options={dropdownValues?.sizes ?? []}
                    getOptionLabel={(option: CreateOrderDropdownValue) => option.value}
                    getOptionValue={(option: CreateOrderDropdownValue) => option.id}
                    onChange={(e: CreateOrderDropdownValue) => onChange(e.id)}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                control={control}
                name="colorId"
                render={({ field: { onChange } }) => (
                  <Dropdown
                    id="create-contract-product-colour"
                    label={t("common.color")}
                    options={dropdownValues?.colors ?? []}
                    getOptionLabel={(option: CreateOrderDropdownValue) => t("common.colors." + option.value)}
                    getOptionValue={(option: CreateOrderDropdownValue) => option.id}
                    onChange={(e: CreateOrderDropdownValue) => onChange(e.id)}
                  />
                )}
              />
            </Grid.Col>
          </Grid.Row>
        </>
      )}
    </>
  );
}
